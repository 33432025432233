import React from 'react'
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';

import {Footer, DownloadCTA} from '../../components';
import {DownloadLayout} from '../../layouts';

class Download extends React.Component {
  constructor(){
    super();
    const { file } = queryString.parse(window.location.search);
    this.state = {
      file
    };
  }

  ref = (link) => {
    this.link = link;
  }

  componentDidMount(){
    if(this.state.file){
      const link = this.link;
      setTimeout(function(){
         const e = document.createEvent('MouseEvents');
         e.initEvent('click', true, true);
         link.dispatchEvent(e);
      }, 2000);
    }
  }

render(){
  const {t} = this.props;
  return(
    <DownloadLayout>
      <div className="container padding_top120 padding_bottom80 min_height800">
        <div className="Download__cta row justify-content-between">
          <div className="col-xl-5 col-md-4 text-right d-none d-md-block bg_holder">
            <img srcSet="/images/download.png 1x, /images/download.png 2x" src="/images/download.png" className="max_height_full max_width_full bg" alt="" />
          </div>
          <div className="col-xl-6 col-md-7 inner">
            <h1 className="font_second light font62 top30 bottom30">{t('page.download.title')}</h1>
            <h3 className="font_second light font24 top30 bottom30">
            {t('page.download.subtitle')}
            </h3>

            <DownloadCTA/>
            <br/>
            <a href={`${this.state.file}`} ref={this.ref} download className="blue">{t('page.download.download')}</a>
          </div>
        </div>
      </div>
    <Footer/>
  </DownloadLayout>
)}
}

export default withTranslation()(Download)
